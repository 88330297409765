import { Table, Typography } from "antd";
import React from "react";
import { useAppSelector } from "stores";
import { Helpers } from "utils";

const { Text } = Typography;

type Props = {
  onChangePage: (page: number) => void;
  filter: any;
};

const CustomText = ({ status, value }: any) => {
  return (
    <Text
      strong
      type={
        status === "WIN" ? "success" : status === "LOST" ? "danger" : undefined
      }
    >
      {value}
    </Text>
  );
};

export default function List(props: Props) {
  const datas = useAppSelector((state) => state.user).predictHistory.datas;
  const meta = useAppSelector((state) => state.user).predictHistory.meta;

  const columns: any = [
    {
      title: "Round",
      dataIndex: "gameId",
      key: "gameId",
      render: (value: number) => `#${value}`,
      align: "center",
    },
    {
      title: "Amount",
      dataIndex: "status",
      key: "amount",
      render: (value: string, record: PredictHistoryType) => (
        <CustomText
          status={value}
          value={
            value === "WIN"
              ? `+ ${Helpers.checkInt(record.amountWin)} ${record.coinType}`
              : `- ${Helpers.checkInt(record.amountLost)} ${record.coinType}`
          }
        />
      ),
      align: "right",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value: string) => <CustomText status={value} value={value} />,
      align: "center",
    },
    {
      title: "Position Win",
      dataIndex: "round",
      key: "round",
      render: (value: RoundType) => <CustomText status={value.positionWin} value={value.positionWin} />,
      align: "center",
    },
    {
      title: "Close Price (USD)",
      dataIndex: "round",
      key: "round",
      render: (value: RoundType, record: PredictHistoryType) => (
        <CustomText
          status={record.status}
          value={Helpers.checkInt(value.closePrice)}
        />
      ),
      align: "right",
    },
    {
      title: "Lock Price (USD)",
      dataIndex: "round",
      key: "round",
      render: (value: RoundType, record: PredictHistoryType) => (
        <CustomText
          status={record.status}
          value={Helpers.checkInt(value.lockPrice)}
        />
      ),
      align: "right",
    },
    {
      title: "Prize Pool",
      dataIndex: "round",
      key: "round",
      render: (value: RoundType, record: PredictHistoryType) => (
        <>
          <CustomText
            status={record.status}
            value={Helpers.checkInt(value.totalAmount) + " USDT"}
          />
          {" - "}
          <CustomText
            status={record.status}
            value={Helpers.checkInt(value.totalTokenAmount) + " IQT"}
          />
        </>
      ),
      align: "right",
    },
  ];

  return (
    <div className="list-container">
      <Table
        columns={columns}
        dataSource={datas.map((item) => ({ key: item.id, ...item }))}
        pagination={{
          defaultPageSize: props.filter ? props.filter.pageSize : 5,
          defaultCurrent: props.filter ? props.filter.page : 1,
          current: props.filter ? props.filter.page : 1,
          showSizeChanger: false,
          total: meta?.total,
          onChange: (page) => props.onChangePage(page),
          showTotal: (total: number) => `Total: ${total}`,
        }}
      />
    </div>
  );
}
