import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import { defaultMeta } from "utils/Mocks/Common";
import CommonSlice from "./CommonSlice";
import PredictHistoryApi from "../apis/PredictHistoryApi";

const initialState = {
  predictHistories: {
    datas: [] as UserPredictHistoryType[],
    meta: defaultMeta as MetaType,
  },
  isRefresh: false as boolean,
};

export const predictHistories = createAsyncThunk(
  "predictHistory/predictHistories",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await PredictHistoryApi.getPredictHistories(params);
      const { data } = response;
      const meta = {
        page: data.data.page || 0,
        pageSize: data.data.pageSize || 0,
        total: data.data.total || 0,
      };
      return {
        meta: meta,
        datas: data.data.datas || [],
      };
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

const PredictHistorySlice = createSlice({
  name: "predictHistory",
  initialState,
    reducers: {
        clearPredictData: (state, action: PayloadAction<void>) => {
            state.predictHistories.datas = [];
        },
    },
  extraReducers: (builder) => {
    builder.addCase(predictHistories.fulfilled, (state, action) => {
      state.isRefresh = false;
      if (action.payload) {
        state.predictHistories.meta = action.payload.meta;
        state.predictHistories.datas = action.payload.datas;
      }
    });
  },
});

export default PredictHistorySlice;
