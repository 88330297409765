import * as USERS from "./Users";
import * as COMMON from "./Common";
import * as TRANSACTION from "./Transaction";
import * as PREDICT from "./Predict";
import * as FILTER from "./Filter";

export default {
  USERS,
  COMMON,
  TRANSACTION,
  FILTER,
  PREDICT
};
