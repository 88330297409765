import queryString from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "stores";
import { Helpers } from "utils";
import List from "./components/List";
import { getUserCommissionHistories } from "stores/UserSlice";

type PropsType = {
  userId: string;
};

export default function CommissionHistories(props: PropsType) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [filter, setFilter] = useState(null);

  const query: any = useMemo(() => {
    const data: any = queryString.parse(location.search);
    return {
      page: data.page ? parseInt(data.page as string) : 1,
      pageSize: data.pageSize ? parseInt(data.pageSize as string) : 5,
      tab: data.tab ? data.tab : "stake-commission",
    };
  }, [location.search]);

  function fetchData() {
    //@ts-ignore
    const params = { ...filter };
    if (filter) {
      delete params.tab;
      params.userId = props.userId;
      dispatch(getUserCommissionHistories(params));
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, props.userId]);

  function onChangePage(page: number) {
    navigate({
      search: queryString.stringify({ ...query, page }),
    });
  }

  useEffect(() => {
    if (JSON.stringify(filter) !== JSON.stringify(Helpers.clean(query))) {
      setFilter({ ...Helpers.clean(query) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);
  return (
    <div>
      <List filter={filter} onChangePage={onChangePage} />
    </div>
  );
}
