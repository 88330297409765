import { Radio, Space } from "antd";
import React from "react";

type Props = {
  filter: any;
  onSearch: (params: any) => void;
};

export default function TabFilter(props: Props) {
  const { filter, onSearch } = props;

  const onChangeTab = (e: any) => {
    const defaultFilter = { ...filter };
    switch (e.target.value) {
      case "ALL":
        defaultFilter.transactionType = null;
        break;
      default:
        defaultFilter.transactionType = e.target.value;
        break;
    }
    onSearch(defaultFilter);
  };
  return (
    <Radio.Group
      value={filter ? filter.transactionType : "ALL"}
      buttonStyle="solid"
      style={{ marginBottom: 15 }}
      onChange={onChangeTab}
    >
      <Space>
        <Radio.Button value="ALL">All</Radio.Button>
        <Radio.Button value="DEPOSIT">Deposit</Radio.Button>
        <Radio.Button value="WITHDRAW">Withdraw</Radio.Button>
        <Radio.Button value="TRANSFER">Transfer</Radio.Button>
        <Radio.Button value="SWAP">Swap</Radio.Button>
      </Space>
    </Radio.Group>
  );
}
