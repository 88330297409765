import queryString from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helpers } from "utils";
import Header from "./components/Header";
import List from "./components/List";
import TabFilter from "./components/TabFilter";
import { useAppDispatch, useAppSelector } from "stores";
import FormSearch from "./components/FormSearch";
import ListFilter from "./components/ListFilter";
import {predictHistories} from "../../stores/PredictHistorySlice";

export default function PredictHistoryPage() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [filter, setFilter] = useState(null);
  const { token } = useAppSelector((state) => state.profile);
  const query: any = useMemo(() => {
    const data: any = queryString.parse(location.search);
    return {
      page: data.page ? parseInt(data.page as string) : 1,
      pageSize: data.pageSize ? parseInt(data.pageSize as string) : 15,
      s: data.s ? data.s : null,
      round: data.round ? data.round : null,
      status: data.status ? data.status : null,
      timeframe: data.timeframe ? data.timeframe : "1",
      coinType: data.coinType ? data.coinType : null,
    };
  }, [location.search]);

  useEffect(() => {
    if (JSON.stringify(filter) !== JSON.stringify(Helpers.clean(query))) {
      setFilter({
        ...Helpers.clean(query),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (filter && token) {
      //@ts-ignore
      const defaultFilter = { ...filter };
      dispatch(predictHistories(defaultFilter));
    }
  }, [filter, token]);

  const onSearch = (params: any) => {
    const filterValue = { ...query, ...params };
    if (params.s === undefined) {
      delete filterValue.s;
    }
    if (params.status === null) {
      delete filterValue.status;
    }
    if (params.round === null) {
      delete filterValue.round;
    }
    if (params.coinType === null) {
      delete filterValue.coinType;
    }
    navigate({
      search: queryString.stringify({ ...filterValue }),
    });
  };

  function onChangePage(page: number) {
    navigate({
      search: queryString.stringify({ ...query, page }),
    });
  }

  return (
    <div>
      <Header />
      <div className="content-container">
        <TabFilter filter={filter} onSearch={onSearch} />
        <div className="search-container">
          <FormSearch filter={filter} onSearch={onSearch} />
          <ListFilter filter={filter} onSearch={onSearch} />
        </div>
        <List filter={filter} onChangePage={onChangePage} />
      </div>
    </div>
  );
}
