import {Button, Form, Input, InputNumber, Select} from "antd";
import { useEffect, useState } from "react";
import { Mocks } from "utils";

type Props = {
  setFilterVisible: (isFilter: boolean) => void;
  onSearch: (filter: any) => void;
  filter: any;
};

export default function FormContent(props: Props) {
  const { filter } = props;
  const [form] = Form.useForm();
  const [filterType, setFilterType] = useState<string>("withStatusActive");

  function onFilter(type: string, value: string) {
    const defaultFilter = { ...filter };
    switch (type) {
      case "withStatusActive":
        defaultFilter.isActive = value;
        props.onSearch(defaultFilter);
        return;
      case "withStatus2FA":
        defaultFilter.enableTwoFactor = value;
        props.onSearch(defaultFilter);
        return;
      case "withSponsor":
        defaultFilter.sponsor = value;
        props.onSearch(defaultFilter);
        return;
      default:
        return;
    }
  }

  function onFinish(values: any) {
    props.setFilterVisible(false);
    form.setFieldsValue({
      filterType: values.filterType,
      filterValue: values.filterValue,
    });
    onFilter(values.filterType, values.filterValue);
  }

  function getOptionFilter(filterType: string) {
    switch (filterType) {
      case "withStatusActive":
        return Mocks.USERS.ActiveStatus;
      case "withStatus2FA":
        return Mocks.USERS.TwoFAStatus;
      default:
        return [];
    }
  }

  useEffect(() => {
    form.setFieldsValue({
      filterType: "withStatusActive",
      filterValue: null,
    });
  }, [form]);

  useEffect(() => {
    switch (form.getFieldValue("filterType")) {
      case "withStatusActive":
        form.setFieldsValue({
          filterType: "withStatusActive",
          filterValue: filter.isActive ? parseInt(filter.isActive) : null,
        });
        return;
      case "withStatus2FA":
        form.setFieldsValue({
          filterType: "withStatus2FA",
          filterValue: filter.enableTwoFactor
            ? parseInt(filter.enableTwoFactor)
            : null,
        });
        return;
      case "withSponsor":
        form.setFieldsValue({
          filterType: "withSponsor",
          filterValue: filter.sponsor
              ? filter.sponsor
              : null,
        });
        return;
    }
  }, [form, form.getFieldValue("filterType"), filter]);

  return (
    <Form form={form} onFinish={onFinish} style={{ width: 300, maxWidth: 300 }}>
      <p>Show all record by</p>
      <Form.Item name="filterType" style={{ marginBottom: 0 }}>
        <Select
          onChange={(e: any) => setFilterType(e)}
          options={Mocks.USERS.filterOptions}
        />
      </Form.Item>
      <div style={{ margin: "10px 0" }}>is</div>

      {
        filterType === "withSponsor" ?
            <Form.Item
                name="filterValue"
                rules={[
                  { required: true, message: "Choose your filter data please!" },
                ]}
            >
              <Input placeholder={"Input sponsor username..."} />
            </Form.Item>:
            <Form.Item
                name="filterValue"
                rules={[
                  { required: true, message: "Choose your filter option please!" },
                ]}
            >
              <Select
                  mode={
                    Mocks.USERS.optionMultiple.includes(filterType)
                        ? "multiple"
                        : undefined
                  }
                  options={getOptionFilter(filterType)}
                  placeholder="Choose your filter option"
              />
            </Form.Item>
      }

      <Form.Item style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          onClick={() => props.setFilterVisible(false)}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button htmlType="submit" type="primary">
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
}
