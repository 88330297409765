import { Button, Card, Form, Input, Space } from "antd";
import SettingApi from "apis/SettingApi";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import CommonSlice from "stores/CommonSlice";

export default function SwapSetting() {
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const { setting } = useAppSelector((state) => state.setting);
    const swapFeeUsdt = setting.find((item) => item.key === "usdt_iqt_swap_fee");
    const swapFeeIqt = setting.find((item) => item.key === "iqt_usdt_swap_fee");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (swapFeeUsdt && swapFeeIqt) {
            form.setFieldsValue({
                swapFeeUsdt: swapFeeUsdt.value * 100,
                swapFeeIqt: swapFeeIqt.value * 100,
            });
        }
    }, [swapFeeUsdt, swapFeeIqt]);

    const onFinish = async (values: any) => {
        setLoading(true);
        try {
            const swapFeeUsdt = {
                key: "usdt_iqt_swap_fee",
                value: parseFloat(values.swapFeeUsdt) / 100,
            };
            const swapFeeIqt = {
                key: "iqt_usdt_swap_fee",
                value: parseFloat(values.swapFeeIqt) / 100,
            };
            const [result1, result2] = await Promise.all([
                SettingApi.updateSetting(swapFeeUsdt),
                SettingApi.updateSetting(swapFeeIqt)
            ]);
            if (result1 && result2) {
                dispatch(
                    CommonSlice.actions.showNotice({
                        type: "success",
                        message: "Success!",
                        description: "Update successful",
                    })
                );
            }
        } catch (error) {
            dispatch(
                CommonSlice.actions.showNotice({
                    type: "error",
                    message: "Error!",
                    description: "Update failed",
                })
            );
        } finally {
            setLoading(false);
        }
    };
    return (
        <Card title="Swap Setting" bordered={false}>
            <Form form={form} name="basic" onFinish={onFinish} layout="vertical">
                <Form.Item
                    label={swapFeeUsdt?.description}
                    name="swapFeeUsdt"
                    rules={[{ required: true, message: "Please input your value!" }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={swapFeeIqt?.description}
                    name="swapFeeIqt"
                    rules={[{ required: true, message: "Please input your value!" }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item>
                    <Button loading={loading} type="primary" htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}
