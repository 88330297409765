import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PromotionApi from "apis/PromotionApi";
import CommonSlice from "./CommonSlice";

const initialState = {
  general: {
    totalPromotionValue: 0,
    totalUser: 0,
    totalVolume: 0,
  } as PromotionGeneralType,
  userJoin: [] as UserJoinPromotionType[],
};

export const getPromotionGeneral = createAsyncThunk(
  "promotion/getPromotionGeneral",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await PromotionApi.generalPromotion(params);
      const { data } = response;
      if (data) {
        return data.data as PromotionGeneralType;
      }
      return null;
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const getUserJoinPromotion = createAsyncThunk(
  "promotion/getUserJoinPromotion",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await PromotionApi.userJoin(params);
      const { data } = response;
      if (data) {
        return data.data as UserJoinPromotionType[];
      }
      return null;
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

const PromotionSlice = createSlice({
  name: "promotion",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPromotionGeneral.fulfilled, (state, action) => {
      if (action.payload) {
        state.general = action.payload;
      }
    });
    builder.addCase(getUserJoinPromotion.fulfilled, (state, action) => {
      if (action.payload) {
        state.userJoin = action.payload;
      }
    });
  },
});

export default PromotionSlice;
