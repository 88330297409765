import { Button, Form, InputNumber, Select } from "antd";
import { useEffect, useState } from "react";
import { Mocks } from "utils";

type Props = {
  setFilterVisible: (isFilter: boolean) => void;
  onSearch: (filter: any) => void;
  filter: any;
};

export default function FormContent(props: Props) {
  const { filter } = props;
  const [form] = Form.useForm();
  const [filterType, setFilterType] = useState<string>("withStatus");

  function onFilter(type: string, value: string) {
    const defaultFilter = { ...filter };
    switch (type) {
      case "withStatus":
        defaultFilter.status = value;
        break;
      case "withNetwork":
        defaultFilter.network = value;
        break;
      case "withCoinType":
        defaultFilter.coinType = value;
        break;
    }
    props.onSearch(defaultFilter);
  }

  function onFinish(values: any) {
    props.setFilterVisible(false);
    form.setFieldsValue({
      filterType: values.filterType,
      filterValue: values.filterValue,
    });
    onFilter(values.filterType, values.filterValue);
  }

  function getOptionFilter(filterType: string) {
    switch (filterType) {
      case "withStatus":
        return Mocks.TRANSACTION.UserCoinTransactionStatus;
      case "withNetwork":
        return Mocks.TRANSACTION.Network;
      case "withCoinType":
        return Mocks.TRANSACTION.coinTypes;
      default:
        return [];
    }
  }

  useEffect(() => {
    form.setFieldsValue({
      filterType: "withStatus",
      filterValue: null,
    });
  }, [form]);

  useEffect(() => {
    switch (form.getFieldValue("filterType")) {
      case "withStatus":
        form.setFieldsValue({
          filterType: "withStatus",
          filterValue: filter.status ? filter.status : null,
        });
        return;
      case "withNetwork":
        form.setFieldsValue({
          filterType: "withNetwork",
          filterValue: filter.network ? filter.network : null,
        });
        return;
      case "withCoinType":
        form.setFieldsValue({
          filterType: "withCoinType",
          filterValue: filter.coinType ? filter.coinType : null,
        });
        return;
      default:
        return;
    }
  }, [form, form.getFieldValue("filterType"), filter]);

  return (
    <Form form={form} onFinish={onFinish} style={{ width: 300, maxWidth: 300 }}>
      <p>Show all record by</p>
      <Form.Item name="filterType" style={{ marginBottom: 0 }}>
        <Select
          onChange={(e: any) => setFilterType(e)}
          options={Mocks.TRANSACTION.filterOptions}
        />
      </Form.Item>
      <div style={{ margin: "10px 0" }}>is</div>

      <Form.Item
        name="filterValue"
        rules={[
          { required: true, message: "Choose your filter option please!" },
        ]}
      >
        <Select
          mode={
            Mocks.TRANSACTION.optionMultiple.includes(filterType)
              ? "multiple"
              : undefined
          }
          options={getOptionFilter(filterType)}
          placeholder="Choose your filter option"
        />
      </Form.Item>

      <Form.Item style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          onClick={() => props.setFilterVisible(false)}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button htmlType="submit" type="primary">
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
}
