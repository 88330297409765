import { Table, Typography } from "antd";
import React from "react";
import { useAppSelector } from "stores";
import { Helpers } from "utils";
import moment from "moment";

const { Text } = Typography;

type Props = {
  onChangePage: (page: number) => void;
  filter: any;
};

const CustomText = ({ status, value }: any) => {
  return (
    <Text
      strong
      type={
        status === "WIN" ? "success" : status === "LOST" ? "danger" : undefined
      }
    >
      {value}
    </Text>
  );
};

export default function List(props: Props) {
  const datas = useAppSelector((state) => state.user).commissionRebate.datas;
  const meta = useAppSelector((state) => state.user).commissionRebate.meta;

  const columns: any = [
    {
      title: "Username",
      dataIndex: "user",
      key: "username",
      render: (value: FriendUserType) => value.username,
    },
     {
      title: "Level",
      dataIndex: "level",
      key: "level",
      render: (value: number) => value,
      align: "center",
     },
    {
      title: "Referral Bonus Earned",
      dataIndex: "commissionAmount",
      key: "commissionAmount",
        align: "center",
    },
    {
      title: "Token",
      dataIndex: "coinType",
      key: "coinType",
      align: "center",
    },
    {
      title: "Friend's Trading Date",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (value: Date) => moment(value).format("YYYY-MM-DD")
    },
      {
          title: "Commission Time",
          dataIndex: "createdAt",
          key: "createdAt",
          align: "center",
          render: (value: Date) => moment(value).format("YYYY-MM-DD hh:mm:ss")
      },
  ];

  return (
    <div className="list-container">
      <Table
        columns={columns}
        dataSource={datas.map((item) => ({ key: item.id, ...item }))}
        pagination={{
          defaultPageSize: props.filter ? props.filter.pageSize : 5,
          defaultCurrent: props.filter ? props.filter.page : 1,
          current: props.filter ? props.filter.page : 1,
          showSizeChanger: false,
          total: meta?.total,
          onChange: (page) => props.onChangePage(page),
          showTotal: (total: number) => `Total: ${total}`,
        }}
      />
    </div>
  );
}
