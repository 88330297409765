import React from "react";
import { Table, Tooltip, Typography } from "antd";
import { useAppSelector } from "stores";

const { Title } = Typography;

export default function UserTable() {
  const { userJoin } = useAppSelector((state) => state.promotion);

  const columns: any = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (value: string) => <Tooltip title={value}>{value}</Tooltip>,
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (value: string) => `${value ? value : ""}`,
    },
    {
      title: "Promotion",
      dataIndex: "promotionValue",
      key: "promotionValue",
    },
    {
      title: "First deposit",
      dataIndex: "firstDepositValue",
      key: "firstDepositValue",
    },
    {
      title: "Total volume",
      dataIndex: "totalVolume",
      key: "totalVolume",
    },
    {
      title: "Trading volume",
      dataIndex: "tradingVolume",
      key: "tradingVolume",
    },
  ];
  return (
    <div className="form-item-container">
      <div style={{ marginBottom: 30 }}>
        <Title level={5}>Users Join</Title>
      </div>
      <Table
        dataSource={userJoin.map((item) => ({ key: item._id, ...item }))}
        columns={columns}
        pagination={undefined}
      />
    </div>
  );
}
