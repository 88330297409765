import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GeneralApi from "apis/GeneralApi";
import CommonSlice from "./CommonSlice";

const initialState = {
  general: null as GeneralDataType | null,
  stakeGeneral: null as any,
  farmingGeneral: null as any,
  dailyVolume: [] as DailyVolumeType[],
  activeUsers: [] as DailyActiveUserType[],
  dailyStaking: [] as DailyStakingVolumeType[],
};

export const getStaticGeneral = createAsyncThunk(
  "general/getStaticGeneral",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await GeneralApi.general(params);
      const { data } = response;
      if (data) {
        return data.data as GeneralDataType;
      }
      return null;
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const getStaticStakeGeneral = createAsyncThunk(
  "general/getStaticStakeGeneral",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await GeneralApi.stakeGeneral(params);
      const { data } = response;
      if (data) {
        return data.data as any;
      }
      return null;
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const getStaticFarmingGeneral = createAsyncThunk(
    "general/getStaticFarmingGeneral",
    async (params: any, thunkApi: any) => {
        thunkApi.dispatch(CommonSlice.actions.incrementLoading());
        try {
            const response = await GeneralApi.farmingGeneral(params);
            const { data } = response;
            if (data) {
                return data.data as any;
            }
            return null;
        } catch (error) {
            return null;
        } finally {
            thunkApi.dispatch(CommonSlice.actions.decrementLoading());
        }
    }
);

export const getDailyVolume = createAsyncThunk(
  "general/getDailyVolume",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await GeneralApi.dailyVolume(params);
      const { data } = response;
      if (data) {
        return data.data as DailyVolumeType[];
      }
      return null;
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const getDailyActiveUser = createAsyncThunk(
  "general/getDailyActiveUser",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await GeneralApi.dailyActiveUser(params);
      const { data } = response;
      if (data) {
        return data.data as DailyActiveUserType[];
      }
      return null;
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const dailyStaking = createAsyncThunk(
  "general/dailyStaking",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await GeneralApi.dailyStaking(params);
      const { data } = response;
      if (data) {
        return data.data as DailyStakingVolumeType[];
      }
      return null;
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

const GeneralSlice = createSlice({
  name: "general",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStaticGeneral.fulfilled, (state, action) => {
      if (action.payload) {
        state.general = action.payload;
      }
    });
    builder.addCase(getStaticStakeGeneral.fulfilled, (state, action) => {
      if (action.payload) {
        state.stakeGeneral = action.payload;
      }
    });
    builder.addCase(getStaticFarmingGeneral.fulfilled, (state, action) => {
      if (action.payload) {
          state.farmingGeneral = action.payload;
      }
    });
    builder.addCase(getDailyVolume.fulfilled, (state, action) => {
      if (action.payload) {
        state.dailyVolume = action.payload;
      }
    });
    builder.addCase(getDailyActiveUser.fulfilled, (state, action) => {
      if (action.payload) {
        state.activeUsers = action.payload;
      }
    });
    builder.addCase(dailyStaking.fulfilled, (state, action) => {
      if (action.payload) {
        state.dailyStaking = action.payload;
      }
    });
  },
});

export default GeneralSlice;
