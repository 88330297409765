const exportedObject = {
  clean: function (obj: any) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  },
  checkInt: function (number: number) {
    const result = number - Math.floor(number) !== 0;
    if (result) {
      return number.toFixed(2);
    }
    return number;
  },
  shortenId: function (id?: string) {
    if (id) {
      return `${id.slice(0, 5)}...${id.slice(-6)}`;
    }
    return null;
  },
  shortenEmail: function (email: string) {
    const name = email.substring(0, email.lastIndexOf("@"));
    const domain = email.substring(email.lastIndexOf("@"));
    return `${name.substring(0, 4)}***${domain}`;
  },
  getUnique: function (arr: any, index: any) {
    const unique = arr
      .map((e: any) => e[index])

      // store the keys of the unique objects
      .map((e: any, i: any, final: any) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e: any) => arr[e])
      .map((e: any) => arr[e]);

    return unique;
  },
};

export const decimalAdjustFloor = (value: number, exp: number) => {
  // If the exp is undefined or zero...
  if (typeof exp === "undefined" || +exp === 0) {
    return Math.floor(value);
  }
  value = +value;
  exp = +exp;
  // If the value is not a number or the exp is not an integer...
  if (
    value === null ||
    isNaN(value) ||
    !(typeof exp === "number" && exp % 1 === 0)
  ) {
    return NaN;
  }
  // Shift
  let newValue: string[] = value.toString().split("e");
  value = Math.floor(
    +(newValue[0] + "e" + (newValue[1] ? +newValue[1] - exp : -exp))
  );
  // Shift back
  newValue = value.toString().split("e");
  return +(newValue[0] + "e" + (newValue[1] ? +newValue[1] + exp : exp));
};

export const roundToPrecision = function (subject: number, precision: number) {
  return +(+subject).toFixed(precision);
};

export const decimalAdjustFloor3 = (value: number) => {
  return decimalAdjustFloor(value, -3);
};

export default exportedObject;
