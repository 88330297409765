import { Table, Tag, Tooltip, Typography } from "antd";
import moment from "moment";
import React, {useMemo} from "react";
import { useAppSelector } from "stores";
import { Helpers } from "utils";
import configs from "../../../configs";
import {useLocation} from "react-router-dom";
import queryString from "query-string";

const { Text } = Typography;

type Props = {
  filter: any;
  onChangePage: (page: number) => void;
};

export default function List(props: Props) {
  const { coinTransaction } = useAppSelector((state) => state.transaction);
  const location = useLocation();
  const query: any = useMemo(() => {
    const data: any = queryString.parse(location.search);
    return {
      transactionType: data.transactionType ? data.transactionType : "ALL",
    };
  }, [location.search]);

  const getStatus = (status: string) => {
    switch (status) {
      case "WAIT_ADMIN_CONFIRM":
        return "Admin Confirm";
      case "WAIT_NETWORK_CONFIRM":
        return "Pending";
      case "CANCELLED":
        return "Cancelled";
      case "SUCCESSFUL":
        return "Successful";
      case "FAILED":
        return "Failed";
    }
  };

  const columns: any = [
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (value: FriendUserType) => (
        <Tooltip title={value.username}>{value.email}</Tooltip>
      ),
    },
    {
      title: "Username",
      dataIndex: "user",
      key: "username",
      render: (value: FriendUserType) => <Text>{value.username}</Text>,
    },
    {
      title: "Transaction Type",
      dataIndex: "transactionType",
      key: "type",
      render: (value: string) => (
        <Tag
          color={
            value === "DEPOSIT"
              ? "green"
              : value === "WITHDRAW"
              ? "cyan"
              : value === "TRANSFER"
              ? "purple"
              : undefined
          }
        >
          {value}
        </Tag>
      ),
    },
    {
      title: "Wallet",
      dataIndex: "walletReceiver",
      key: "walletReceiver",
      render: (value: string) =>
        value && (
          <Text copyable={{ text: value }}>{Helpers.shortenId(value)}</Text>
        ),
    },
    {
      title: "TxID",
      dataIndex: "txHash",
      key: "txHash",
      render: (value: string, record: any) =>
        value && (
          <Text copyable={{ text: value }}>
            <a
              target={"_blank"}
              href={
                record.network === "BSC"
                  ? configs.bscExplorer + value
                  : record.network === "TRX"
                  ? configs.tronExplorer + value
                  : "#"
              }
            >
              {value == "Internal" ? value : Helpers.shortenId(value)}
            </a>
          </Text>
        ),
    },
    {
      title: "Network",
      dataIndex: "network",
      key: "Network",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (value: number, record: CoinTransactionType) => (
        <div style={{ textAlign: "center" }}>
          {Helpers.checkInt(value)} {record.coinType}
        </div>
      ),
      align: "right",
    },
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "time",
      render: (value: string) => (
        <span>
          {moment(value).format("DD/MM/YYYY")}
          <br />
          {moment(value).format("HH:mm")}
        </span>
      ),
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value: string) => (
        <Text
          strong
          type={
            value === "SUCCESSFUL"
              ? "success"
              : value === "FAILED"
              ? "danger"
              : undefined
          }
        >
          {getStatus(value)}
        </Text>
      ),
      align: "center",
    },
  ];
  const columnSwap: any = [
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (value: FriendUserType) => (
        <Tooltip title={value.username}>{value.email}</Tooltip>
      ),
    },
    {
      title: "Username",
      dataIndex: "user",
      key: "username",
      render: (value: FriendUserType) => <Text>{value.username}</Text>,
    },
    {
      title: "Transaction Type",
      dataIndex: "transactionType",
      key: "type",
      render: (value: string) => (
        <Tag
          color={
            value === "DEPOSIT"
              ? "green"
              : value === "WITHDRAW"
                ? "cyan"
                : value === "TRANSFER"
                  ? "purple"
                  : undefined
          }
        >
          {value}
        </Tag>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (value: number, record: CoinTransactionType) => (
        <div style={{ textAlign: "center" }}>
          {Helpers.checkInt(value || 0)} {record.coinType}
        </div>
      ),
      align: "right",
    },
    {
      title: "To Amount",
      dataIndex: "toAmount",
      key: "toAmount",
      render: (value: number, record: CoinTransactionType) => (
        <div style={{ textAlign: "center" }}>
          {Helpers.checkInt(value || 0)} {record.toCoinType}
        </div>
      ),
      align: "right",
    },
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "time",
      render: (value: string) => (
        <span>
          {moment(value).format("DD/MM/YYYY")}
          <br />
          {moment(value).format("HH:mm")}
        </span>
      ),
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value: string) => (
        <Text
          strong
          type={
            value === "SUCCESSFUL"
              ? "success"
              : value === "FAILED"
                ? "danger"
                : undefined
          }
        >
          {getStatus(value)}
        </Text>
      ),
      align: "center",
    },
  ];
  const columnTransfer: any = [
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (value: FriendUserType) => (
        <div>
          <div><Text>{value.username}</Text></div>
          <div><Tooltip title={value.username}>{value.email}</Tooltip></div>
        </div>
      ),
    },
    {
      title: "Receiver",
      dataIndex: "receiver",
      key: "receiver",
      render: (value: FriendUserType) => (
        <div>
          <div><Text>{value?.username}</Text></div>
          <div><Tooltip title={value?.username}>{value?.email}</Tooltip></div>
        </div>
      ),
    },
    {
      title: "Transaction Type",
      dataIndex: "transactionType",
      key: "type",
      render: (value: string) => (
        <Tag
          color={
            value === "DEPOSIT"
              ? "green"
              : value === "WITHDRAW"
                ? "cyan"
                : value === "TRANSFER"
                  ? "purple"
                  : undefined
          }
        >
          {value}
        </Tag>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (value: number, record: CoinTransactionType) => (
        <div style={{ textAlign: "right" }}>
          {Helpers.checkInt(value)} {record.coinType}
        </div>
      ),
      align: "right",
    },
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "time",
      render: (value: string) => (
        <span>
          {moment(value).format("DD/MM/YYYY")}
          <br />
          {moment(value).format("HH:mm")}
        </span>
      ),
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value: string) => (
        <Text
          strong
          type={
            value === "SUCCESSFUL"
              ? "success"
              : value === "FAILED"
                ? "danger"
                : undefined
          }
        >
          {getStatus(value)}
        </Text>
      ),
      align: "center",
    },
  ];

  return (
    <div className="list-container">
      <Table
        dataSource={coinTransaction.datas.map((item) => ({
          key: item.id,
          ...item,
        }))}
        columns={query.transactionType && query.transactionType === "SWAP" ? columnSwap: query.transactionType && query.transactionType === "TRANSFER" ? columnTransfer: columns}
        pagination={{
          defaultPageSize: props.filter ? props.filter.pageSize : 15,
          defaultCurrent: props.filter ? props.filter.page : 1,
          current: props.filter ? props.filter.page : 1,
          showSizeChanger: false,
          total: coinTransaction.meta.total,
          showTotal: (total: number) => `Total: ${total}`,
          onChange: (page) => props.onChangePage(page),
        }}
      />
    </div>
  );
}
