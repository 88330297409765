import { Descriptions, Typography } from "antd";
import React from "react";
import { useAppSelector } from "stores";
import { Helpers } from "utils";

const { Text } = Typography;

export default function TokenPredictionInfo() {
  const {userTokenPredictPnl } = useAppSelector((state) => state.user);
  return (
    <div className="form-item-container">
      <Descriptions column={6} title="Prediction PNL (IQT)">
        <Descriptions.Item span={3} label="Win">
          <Text strong>{`${userTokenPredictPnl?.totalWin || 0} Round / ${
            userTokenPredictPnl?.totalAmountWin || 0
          } IQT`}</Text>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Loss">
          <Text strong>{`${userTokenPredictPnl?.totalLost || 0} Round / ${
            userTokenPredictPnl?.totalAmountLost || 0
          } IQT`}</Text>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Volume">
          <Text strong>
            {Helpers.checkInt(userTokenPredictPnl?.totalAmount ?? 0)} IQT
          </Text>
        </Descriptions.Item>

        <Descriptions.Item span={3} label="PnL">
          {userTokenPredictPnl ? (
            <Text
              type={
                userTokenPredictPnl.totalAmountWin - userTokenPredictPnl.totalAmountLost >
                0
                  ? "success"
                  : "danger"
              }
              strong
            >
              {Helpers.checkInt(
                userTokenPredictPnl.totalAmountWin - userTokenPredictPnl.totalAmountLost
              )}{" "}
              IQT
            </Text>
          ) : (
            <Text strong>0 IQT</Text>
          )}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}
