import { unwrapResult } from "@reduxjs/toolkit";
import { Modal, Radio, RadioChangeEvent } from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import { updateVip } from "stores/UserSlice";

type Props = {
  isOpen: boolean;
  defaultLevel?: number;
  handleCancel: () => void;
};

export default function PopupUpdateVipLevel(props: Props) {
  const dispatch = useAppDispatch();
  const { defaultLevel, isOpen, handleCancel } = props;
  const [value, setValue] = useState(1);

  const { detail } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (defaultLevel) {
      setValue(defaultLevel);
    }
  }, [defaultLevel]);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const onUpdate = async (params: any) => {
    const rs = unwrapResult(await dispatch(updateVip(params)));
    if (rs) {
      handleCancel();
    }
  };

  const handleOk = () => {
    if (detail) {
      const params = {
        userId: detail.id,
        level: value,
      };
      onUpdate(params);
    }
  };

  return (
    <Modal
      title="Update VIP Level"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Radio.Group onChange={onChange} value={value}>
        <Radio value={1}>VIP 1</Radio>
        <Radio value={2}>VIP 2</Radio>
        <Radio value={3}>VIP 3</Radio>
        <Radio value={4}>VIP 4</Radio>
        <Radio value={5}>VIP 5</Radio>
      </Radio.Group>
    </Modal>
  );
}
