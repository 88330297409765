import { Button, Card, Form, Input, InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import {eiqtSettingUpdate, iqtSettingUpdate} from "stores/SettingSlice";

export default function EIQTPriceSetting() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { eiqt } = useAppSelector((state) => state.setting);

  useEffect(() => {
    if (eiqt) {
      form.setFieldsValue({
        eiqt: eiqt,
      });
    }
  }, [eiqt]);

  const onFinish = (values: any) => {
    const params = {
      value: values.eiqt,
    };
    dispatch(eiqtSettingUpdate(params));
  };

  return (
    <Card title="eIQT Price" bordered={false}>
      <Form form={form} name="basic" onFinish={onFinish} layout="vertical">
        <Form.Item
          label="eIQT price"
          name="eiqt"
          rules={[{ required: true, message: "Please input your value!" }]}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
