import {
  Button,
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment";
import React from "react";
import { useAppDispatch, useAppSelector } from "stores";
import { Helpers } from "utils";
import { approve, cancel } from "stores/TransactionHistorySlice";

const { Text, Title } = Typography;

type Props = {
  filter: any;
  onChangePage: (page: number) => void;
};

export default function List(props: Props) {
  const dispatch = useAppDispatch();
  const { coinTransaction } = useAppSelector((state) => state.transaction);

  const columns: any = [
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (value: FriendUserType) => (
        <Tooltip title={value.username}>{value.email}</Tooltip>
      ),
    },
    {
      title: "Wallet Receiver",
      dataIndex: "walletReceiver",
      key: "walletReceiver",
      render: (value: string) =>
        value && <Text copyable={{ text: value }}>{value}</Text>,
    },
    {
      title: "Network",
      dataIndex: "network",
      key: "Network",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (value: number, record: CoinTransactionType) => (
        <Text strong type="success">
          {Helpers.checkInt(value)} {record.coinType}
        </Text>
      ),
      align: "right",
    },
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "time",
      render: (value: string) => {
        moment(value).format("DD/MM/YYYY HH:mm");
      },
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: CoinTransactionType) => (
        <Space direction="vertical">
          <Popconfirm
            title="Approve the withdraw"
            description="Are you sure to approve this withdraw?"
            onConfirm={() => dispatch(approve(record.id))}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary">Approve</Button>
          </Popconfirm>
          <Popconfirm
            title="Cancel the withdraw"
            description="Are you sure to cancel this withdraw?"
            onConfirm={() => dispatch(cancel(record.id))}
            // onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger>
              Cancel
            </Button>
          </Popconfirm>
        </Space>
      ),
      align: "center",
    },
  ];
  return (
    <div className="list-container">
      <Table
        dataSource={coinTransaction.datas.map((item) => ({
          key: item.id,
          ...item,
        }))}
        columns={columns}
        pagination={{
          defaultPageSize: props.filter ? props.filter.pageSize : 15,
          defaultCurrent: props.filter ? props.filter.page : 1,
          current: props.filter ? props.filter.page : 1,
          showSizeChanger: false,
          total: coinTransaction.meta.total,
          showTotal: (total: number) => `Total: ${total}`,
          onChange: (page) => props.onChangePage(page),
        }}
      />
    </div>
  );
}
