import { Button, Card, Form, Input, Space } from "antd";
import SettingApi from "apis/SettingApi";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import CommonSlice from "stores/CommonSlice";

export default function WithdrawLimitSetting() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { setting } = useAppSelector((state) => state.setting);
  const usdt = setting.find((item) => item.key === "max_day_withdraw_amount");
  const iqt = setting.find((item) => item.key === "iqt_max_day_withdraw_amount");
  const eiqt = setting.find((item) => item.key === "eiqt_max_day_withdraw_amount");
  const bnb = setting.find((item) => item.key === "bnb_max_day_withdraw_amount");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (usdt && iqt) {
      form.setFieldsValue({
        usdt: usdt.value,
        iqt: iqt.value,
        eiqt: eiqt?.value,
        bnb: bnb?.value
      });
    }
  }, [usdt, iqt, eiqt, bnb]);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const usdtParams = {
        key: "max_day_withdraw_amount",
        value: parseFloat(values.usdt),
      };
      const iqtParams = {
        key: "iqt_max_day_withdraw_amount",
        value: parseFloat(values.iqt),
      };
      const eiqtParams = {
        key: "eiqt_max_day_withdraw_amount",
        value: parseFloat(values.eiqt),
      };
      const bnbParams = {
        key: "bnb_max_day_withdraw_amount",
        value: parseFloat(values.bnb),
      };
      const [result1, result2, result3, result4] = await Promise.all([
        SettingApi.updateSetting(usdtParams),
        SettingApi.updateSetting(iqtParams),
        SettingApi.updateSetting(eiqtParams),
        SettingApi.updateSetting(bnbParams),
      ]);
      if (result1 && result2 && result3 && result4) {
        dispatch(
          CommonSlice.actions.showNotice({
            type: "success",
            message: "Success!",
            description: "Update successful",
          })
        );
      }
    } catch (error) {
      dispatch(
        CommonSlice.actions.showNotice({
          type: "error",
          message: "Error!",
          description: "Update failed",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <Card title="Withdraw Limit" bordered={false}>
      <Form form={form} name="basic" onFinish={onFinish} layout="vertical">
        <Form.Item
          label={usdt?.description}
          name="usdt"
          rules={[{ required: true, message: "Please input your value!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={iqt?.description}
          name="iqt"
          rules={[{ required: true, message: "Please input your value!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
            label={eiqt?.description}
            name="eiqt"
            rules={[{ required: true, message: "Please input your value!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
            label={bnb?.description}
            name="bnb"
            rules={[{ required: true, message: "Please input your value!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
