import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import CommonSlice from "./CommonSlice";
import ProfileSlice from "./ProfileSlice";
import UserSlice from "./UserSlice";
import GeneralSlice from "./GeneralSlice";
import TransactionHistorySlice from "./TransactionHistorySlice";
import PredictionSlice from "./PredictionSlice";
import SettingSlice from "./SettingSlice";
import PromotionSlice from "./PromotionSlice";
import PredictHistorySlice from "./PredictHistorySlice";

const rootReducer = {
  common: CommonSlice.reducer,
  profile: ProfileSlice.reducer,
  user: UserSlice.reducer,
  general: GeneralSlice.reducer,
  transaction: TransactionHistorySlice.reducer,
  predictHistory: PredictHistorySlice.reducer,
  prediction: PredictionSlice.reducer,
  setting: SettingSlice.reducer,
  promotion: PromotionSlice.reducer,
};

const store = configureStore({ reducer: rootReducer });

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type StoreType = typeof store;
export default store;
