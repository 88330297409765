import { RequestManager } from "utils";
import config from "configs";

const coinTransactions = (params: any) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/coin-transactions`,
    params
  );
};

const approve = (id: string) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/coin-transactions/${id}/approve-withdraw`
  );
};

const cancel = (id: string) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/coin-transactions/${id}/cancel-withdraw`
  );
};

const exported = {
  coinTransactions,
  approve,
  cancel,
};

export default exported;
