import { Button, Space, Table, Tag, Tooltip, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "stores";
import { Helpers } from "utils";
const { Text } = Typography;
type PropsType = {
  filter: any;
  onChangePage: (page: number) => void;
};
export default function List(props: PropsType) {
  const navigate = useNavigate();
  const { users, meta } = useAppSelector((state) => state.user);

  const columns: any = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (value: string) => <Tooltip title={value}>{value}</Tooltip>,
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (value: string) => `${value ? value : ""}`,
    },
    {
      title: "Wallet",
      dataIndex: "bscWalletAddress",
      key: "bscWalletAddress",
      render: (value: string) => (
        <Text
          copyable={{
            text: value,
          }}
        >
          {Helpers.shortenId(value)}
        </Text>
      ),
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (value: boolean) =>
        value ? <Tag color="#108ee9">Active</Tag> : <Tag>Deactive</Tag>,
      align: "center",
    },
    {
      title: "TwoFactor",
      dataIndex: "enableTwoFactor",
      key: "enableTwoFactor",
      render: (value: boolean) =>
        value ? <Tag color="#108ee9">Enable</Tag> : <Tag>Disable</Tag>,
      align: "center",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_: string, record: UserType) => (
        <Space direction="vertical">
          <Button
            onClick={() => navigate(`/users/${record.id}`)}
            size="small"
            type="primary"
          >
            Detail
          </Button>
        </Space>
      ),
      align: "right",
    },
  ];
  return (
    <div className="list-container">
      <Table
        dataSource={users.map((item) => ({ key: item.id, ...item }))}
        columns={columns}
        pagination={{
          defaultPageSize: props.filter ? props.filter.pageSize : 15,
          defaultCurrent: props.filter ? props.filter.page : 1,
          current: props.filter ? props.filter.page : 1,
          showSizeChanger: false,
          total: meta.total,
          showTotal: (total: number) => `Total: ${total}`,
          onChange: (page) => props.onChangePage(page),
        }}
      />
    </div>
  );
}
