import { Descriptions, Typography } from "antd";
import React from "react";
import { useAppSelector } from "stores";
import { Helpers } from "utils";

const { Text } = Typography;

export default function GeneralView() {
  const { general } = useAppSelector((state) => state.promotion);
  return (
    <div className="form-item-container">
      <Descriptions title="General">
        <Descriptions.Item label="Total Volume">
          <Text strong>{Helpers.checkInt(general?.totalVolume || 0)}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Total User">
          <Text strong>{Helpers.checkInt(general?.totalUser || 0)}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Total Promotion Value">
          <Text strong>
            {Helpers.checkInt(general?.totalPromotionValue || 0)}
          </Text>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}
