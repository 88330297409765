import { Routes, Route } from "react-router-dom";
import MainLayout from "./MainLayout/MainLayout";
import RequireAuth from "components/RequireAuth";
import General from "pages/general/General";
import Login from "pages/login/LoginPage";
import Users from "pages/users/UsersPage";
import DetailUser from "pages/users/Details/DetailUserPage";
import TransactionHistoryPage from "pages/transactionHistory/TransactionHistoryPage";
import WithdrawRequestPage from "pages/withdrawRequest/WithdrawRequestPage";
import PredictionPage from "pages/predictions/PredictionPage";
import SettingPage from "pages/settings/SettingPage";
import PredictionConfigHistories from "../pages/predicttionConfigs/PredictionConfigHistories";
import PromotionsPage from "../pages/promotions/PromotionsPage";
import PredictHistoryPage from "../pages/predictHistory/PredictHistoryPage";

function LayoutDemo() {
  return <MainLayout />;
}

export default function MainRouter() {
  return (
    <Routes>
      <Route element={<LayoutDemo />}>
        <Route
          path="/"
          element={
            <RequireAuth>
              <General />
            </RequireAuth>
          }
        />
        <Route
          path="/general"
          element={
            <RequireAuth>
              <General />
            </RequireAuth>
          }
        />
        <Route
          path="/users"
          element={
            <RequireAuth>
              <Users />
            </RequireAuth>
          }
        />
        <Route
          path="/users/:id"
          element={
            <RequireAuth>
              <DetailUser />
            </RequireAuth>
          }
        />
        <Route
          path="/transaction-histories"
          element={
            <RequireAuth>
              <TransactionHistoryPage />
            </RequireAuth>
          }
        />
          <Route
              path="/predict-histories"
              element={
                  <RequireAuth>
                      <PredictHistoryPage />
                  </RequireAuth>
              }
          />
        <Route
          path="/withdraw-requests"
          element={
            <RequireAuth>
              <WithdrawRequestPage />
            </RequireAuth>
          }
        />
        <Route
          path="/settings"
          element={
            <RequireAuth>
              <SettingPage />
            </RequireAuth>
          }
        />
        <Route
          path="/predictions"
          element={
            <RequireAuth>
              <PredictionPage />
            </RequireAuth>
          }
        />
        <Route
          path="/prediction-histories"
          element={
            <RequireAuth>
              <PredictionConfigHistories />
            </RequireAuth>
          }
        />
        <Route
          path="/promotions"
          element={
            <RequireAuth>
              <PromotionsPage />
            </RequireAuth>
          }
        />
      </Route>

      <Route path="/login" element={<Login />} />
      <Route path="*" element={<p>There's nothing here: 404!</p>} />
    </Routes>
  );
}
