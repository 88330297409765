import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import CommonSlice from "./CommonSlice";
import AuthenApi from "../apis/AuthenApi";

const initialState = {
  token: "" as string,
  admin: {
    id: ""
  } as AdminType
};

export const adminInfo = createAsyncThunk(
    "profile/admin",
    async (_, thunkApi: any) => {
      thunkApi.dispatch(CommonSlice.actions.incrementLoading());
      try {
        const response = await AuthenApi.adminInfo();
        const { data } = response;
        if (data) {
          return data.data as AdminType;
        }
        return null;
      } catch (error) {
        return null;
      } finally {
        thunkApi.dispatch(CommonSlice.actions.decrementLoading());
      }
    }
);

const ProfileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(adminInfo.fulfilled, (state, action) => {
      if (action.payload) {
        state.admin = action.payload;
      }
    });
  },
});

export default ProfileSlice;
