import { Button, Form, Input, message, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthenApi from "apis/AuthenApi";
import { useAppDispatch, useAppSelector } from "stores";
import ProfileSlice from "stores/ProfileSlice";

const { Title } = Typography;

export default function LoginPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const token = useAppSelector((state) => state.profile).token;

  async function onLogin(params: LoginParamsType) {
    setIsLoading(true);
    try {
      const response = await AuthenApi.login(params);
      const { data } = response;
      if (data.meta.status === 200) {
        localStorage.setItem("AccessToken", data.data.accessToken);
        dispatch(ProfileSlice.actions.setToken(data.data.accessToken));
        window.location.href = "/general";
      } else {
        message.error(data.meta.externalMessage);
      }
    } catch (error) {
      message.error("Error! An error occurred. Please try again later");
    } finally {
      setIsLoading(false);
    }
  }

  const onFinish = (values: LoginParamsType) => {
    const params = {
      username: values.username.toLowerCase(),
      password: values.password,
    };
    onLogin(params);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (token) {
        window.location.href = "/general";
    }
  }, [navigate, token]);
  return (
    <Spin spinning={isLoading}>
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#0F3662",
        }}
      >
        <div
          style={{
            maxWidth: 1000,
            height: 500,
            width: "100%",
            padding: 80,
            backgroundColor: "#08213A",
            borderRadius: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Title style={{ color: "#fff" }}>LOGIN</Title>
            <Form
              name="basic"
              // initialValues={{ remember: true }}
              style={{ marginTop: 20, width: 400 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                name="username"
                rules={[
                  { required: true, message: "Please enter your username!" },
                ]}
              >
                <Input size="large" placeholder="Enter Username" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please enter your password!" },
                ]}
              >
                <Input.Password size="large" placeholder="Enter Password" />
              </Form.Item>

              {/* <Form.Item name="remember" valuePropName="checked">
              <Checkbox style={{ color: "#fff" }}>Remember me</Checkbox>
            </Form.Item> */}

              <Form.Item>
                <Button size="large" type="primary" htmlType="submit">
                  Log In
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </Spin>
  );
}
