import { Button, Col, Input, Popover, Row } from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import useDebounce from "hooks/useDebounce";
import FilterContent from "./FilterContent";

type Props = {
  onSearch: (params: any) => void;
  filter: any;
};

export default function FormSearch(props: Props) {
  const { filter } = props;
  const [searchValue, setSearchValue] = useState("");
  const [filterVisible, setFilterVisible] = useState(false);
  const inputDebounce = useDebounce(searchValue, 300);

  useEffect(() => {
    if (filter && filter.s) {
      setSearchValue(filter.s);
    }
  }, [filter]);

  useEffect(() => {
    if (filter && searchValue === "") {
      const defaultFilter = { ...filter };
      delete defaultFilter.s;
      props.onSearch(defaultFilter);
    }
  }, [filter, searchValue]);

  useEffect(() => {
    const defaultFilter = { ...filter };
    if (inputDebounce) {
      defaultFilter.s = searchValue;
      props.onSearch(defaultFilter);
      return;
    }
  }, [inputDebounce]);

  const content = (
    <FilterContent
      onSearch={props.onSearch}
      setFilterVisible={setFilterVisible}
      filter={filter}
    />
  );

  return (
    <Row>
      <Col flex="100px">
        <Popover
          placement="bottom"
          title={"Filter"}
          content={content}
          trigger="click"
          open={filterVisible}
          onOpenChange={(visible) => setFilterVisible(visible)}
        >
          <Button size="large" icon={<FilterOutlined />}>
            Filter
          </Button>
        </Popover>
      </Col>
      <Col flex="auto" className="ml-10">
        <Input
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          allowClear
          size="large"
          placeholder="Search"
          prefix={<SearchOutlined />}
        />
      </Col>
    </Row>
  );
}
