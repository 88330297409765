import {
  Button,
  Descriptions,
  Divider,
  Input,
  InputNumber,
  Radio,
  Typography,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import { updateUser } from "stores/UserSlice";
import { Helpers } from "utils";
import { EditOutlined } from "@ant-design/icons";
import PopupUpdateVipLevel from "./PopupUpdateVipLevel";

const { Text } = Typography;

export default function InfoUser() {
  const dispatch = useAppDispatch();
  const [activeStatus, setActiveStatus] = useState("");
  const [twofactor, setTwofactor] = useState("");
  const [email, setEmail] = useState("");
  const [rank, setRank] = useState<number>(0);
  const [vip, setVip] = useState<number>(0);
  const [password, setPassword] = useState("");
  const { detail } = useAppSelector((state) => state.user);

  const [isUpdateLevel, setIsUpdateLevel] = useState(false);

  useEffect(() => {
    if (detail) {
      setActiveStatus(detail.isActive ? "active" : "deactive");
      setTwofactor(detail.enableTwoFactor ? "enable" : "disable");
      setEmail(detail.email);
      setRank(detail.rank);
    }
  }, [detail]);

  function onChangeRadio(e: any, key: string) {
    const value = e.target.value;
    switch (key) {
      case "active":
        setActiveStatus(value);
        return;
      case "twofactor":
        setTwofactor(value);
        return;
    }
  }

  const onChangeRank = (value: number | null) => {
    setRank(value || 0);
  };

  const onChangeVip = (value: number | null) => {
    setVip(value || 0);
  };

  const onSave = () => {
    if (detail) {
      const params: any = {
        id: detail.id,
        email,
        isActive: activeStatus === "active" ? true : false,
        enableTwoFactor: twofactor === "enable" ? true : false,
        rank,
      };
      if (params.email === detail.email) {
        //@ts-ignore
        delete params.email;
      }
      if (password && password.length > 0) {
        params.password = password;
      }
      dispatch(updateUser(params));
    }
  };

  return (
    <div className="form-item-container">
      <Descriptions column={12} title="User Info">
        <Descriptions.Item span={3} label="Username">
          <Text strong>{detail?.username}</Text>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="UserId">
          <Text strong>{detail?.shortId}</Text>
        </Descriptions.Item>

        <Descriptions.Item span={6} label="Sponsor">
          <Text strong>{detail?.sponsor}</Text>
        </Descriptions.Item>

        <Descriptions.Item span={6} label="Email">
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ width: 380 }}
          />
        </Descriptions.Item>
        <Descriptions.Item span={6} label="Password">
          <Input.Password
            style={{ width: 300 }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Descriptions.Item>
        <Descriptions.Item span={6} label="BSC Wallet">
          <Text strong>{detail?.bscWalletAddress}</Text>
        </Descriptions.Item>
        <Descriptions.Item span={6} label="Tron Wallet">
          <Text strong>{detail?.tronWalletAddress}</Text>
        </Descriptions.Item>
        <Descriptions.Item span={6} label="Status active">
          <Radio.Group
            value={activeStatus}
            onChange={(e) => onChangeRadio(e, "active")}
          >
            <Radio value={"active"}>Active</Radio>
            <Radio value={"deactive"}>Deactive</Radio>
          </Radio.Group>
        </Descriptions.Item>
        <Descriptions.Item span={6} label="Two Factor">
          <Radio.Group
            value={twofactor}
            onChange={(e) => onChangeRadio(e, "twofactor")}
          >
            <Radio value={"enable"}>Enable</Radio>
            <Radio value={"disable"}>Disable</Radio>
          </Radio.Group>
        </Descriptions.Item>
        {/* <Descriptions.Item span={3} label="Rank">
          <InputNumber min={1} max={10} value={rank} onChange={onChangeRank} />
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Balance (USD)">
          {detail?.balance}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="IQT Balance">
          {detail?.tokenBalance}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="IQT Frozen">
          {detail?.tokenFrozen}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="IQT Escrowed">
          {detail?.tokenEs}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="ID">
          {detail?.shortId}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Sponsor">
          {detail?.sponsor}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="License">
          {detail?.isActiveLicense ? "Yes" : "No"}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Commission Earned">
          {Helpers.checkInt(detail?.totalCommissionEarned || 0)}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Total Deposit">
          {Helpers.checkInt(detail?.totalDeposit || 0)}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Total Withdraw">
          {Helpers.checkInt(detail?.totalWithdraw || 0)}
        </Descriptions.Item> */}
      </Descriptions>
      <div style={{ textAlign: "right" }}>
        <Button onClick={() => onSave()} type="primary" size="large">
          Save User
        </Button>
      </div>
      {/* <Divider className="mx-10" /> */}
      {/* <Descriptions column={6}>
        <Descriptions.Item span={3} label="Status active">
          <Radio.Group
            value={activeStatus}
            onChange={(e) => onChangeRadio(e, "active")}
          >
            <Radio value={"active"}>Active</Radio>
            <Radio value={"deactive"}>Deactive</Radio>
          </Radio.Group>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Two Factor">
          <Radio.Group
            value={twofactor}
            onChange={(e) => onChangeRadio(e, "twofactor")}
          >
            <Radio value={"enable"}>Enable</Radio>
            <Radio value={"disable"}>Disable</Radio>
          </Radio.Group>
        </Descriptions.Item>
      </Descriptions>
      <div style={{ textAlign: "right" }}>
        <Button onClick={() => onSave()} type="primary" size="large">
          Save User
        </Button>
      </div>
      <Divider className="mx-10" />
      <Descriptions column={6} title={"Staking Info"}>
        <Descriptions.Item span={2} label="Stake">
          {Helpers.checkInt(detail?.staking?.stakingValue || 0)}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Harvest">
          {Helpers.checkInt(detail?.staking?.harvestValue || 0)}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Commission">
          {Helpers.checkInt(detail?.staking?.commissionValue || 0)}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Total Staked">
          {Helpers.checkInt(detail?.staking?.totalStakingValue || 0)}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Total Unstaked">
          {Helpers.checkInt(detail?.staking?.totalUnstakedValue || 0)}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Total Commission">
          {Helpers.checkInt(detail?.staking?.totalCommissionValue || 0)}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="VIP">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <span style={{ marginRight: 10 }}>
              {detail?.userCommission.stakingSaleLevel}
            </span>
            <Tooltip title="Edit user VIP level">
              <Button
                onClick={() => setIsUpdateLevel(true)}
                type="link"
                icon={<EditOutlined />}
              ></Button>
            </Tooltip>
          </div>
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Staking Sale">
          {detail?.userCommission.totalStakingSale}
        </Descriptions.Item>
      </Descriptions> */}
    </div>
  );
}
