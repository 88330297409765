import { Descriptions, Typography } from "antd";
import React from "react";
import { useAppSelector } from "stores";
import { Helpers } from "utils";

const { Text } = Typography;

export default function PredictionInfo() {
  const { detail, userPredictPnl } = useAppSelector((state) => state.user);
  return (
    <div className="form-item-container">
      <Descriptions column={6} title="Prediction PNL (USDT)">
        <Descriptions.Item span={3} label="Win">
          <Text strong>{`${userPredictPnl?.totalWin || 0} Round / ${
            userPredictPnl?.totalAmountWin || 0
          } USDT`}</Text>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Loss">
          <Text strong>{`${userPredictPnl?.totalLost || 0} Round / ${
            userPredictPnl?.totalAmountLost || 0
          } USDT`}</Text>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Volume">
          <Text strong>
            {Helpers.checkInt(userPredictPnl?.totalAmount ?? 0)} USDT
          </Text>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="PnL">
          {userPredictPnl ? (
            <Text
              type={
                userPredictPnl.totalAmountWin - userPredictPnl.totalAmountLost >
                0
                  ? "success"
                  : "danger"
              }
              strong
            >
              {Helpers.checkInt(
                userPredictPnl.totalAmountWin - userPredictPnl.totalAmountLost
              )}{" "}
              USDT
            </Text>
          ) : (
            <Text strong>0 USDT</Text>
          )}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}
