import { Descriptions, Typography } from "antd";
import React from "react";
import { useAppSelector } from "stores";
import { Helpers } from "utils";

const { Text } = Typography;

export default function PredictionCommission() {
  const { detail } = useAppSelector((state) => state.user);
  return (
    <div className="form-item-container">
      <Descriptions column={6} title="Prediction Commission">
        <Descriptions.Item span={3} label="Level">
          <Text strong>{detail?.userCommission.rank || 0}</Text>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="License">
          <Text strong>{detail?.isActiveLicense ? "Yes" : "No"}</Text>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Sales">
          <Text strong>Updating</Text>
        </Descriptions.Item>

        <Descriptions.Item span={3} label="Commisstion">
          <Text strong>
            {Helpers.checkInt(detail?.totalCommissionEarned || 0)} USDT / {Helpers.checkInt(detail?.totalTokenCommissionEarned || 0)} IQT
          </Text>
        </Descriptions.Item>

      </Descriptions>
    </div>
  );
}
