import {Radio, Space, Typography} from "antd";
import React from "react";
const { Text } = Typography;
type Props = {
  filter: any;
  onSearch: (params: any) => void;
};

export default function TabFilter(props: Props) {
  const { filter, onSearch } = props;

  const onChangeTab = (e: any) => {
    const defaultFilter = { ...filter };
    defaultFilter.timeframe = e.target.value;
    onSearch(defaultFilter);
  };
  return (
    <Radio.Group
      value={filter ? filter.timeframe : "ALL"}
      buttonStyle="solid"
      style={{ marginBottom: 15 }}
      onChange={onChangeTab}
    >
      <Space>
        <Text strong>Timeframe: </Text>
        <Radio.Button value="1">1M</Radio.Button>
        <Radio.Button value="3">3M</Radio.Button>
      </Space>
    </Radio.Group>
  );
}
