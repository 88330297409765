import { RequestManager } from "utils";
import config from "configs";

const login = (params: LoginParamsType) => {
  return RequestManager.v1.withAuthorize.post(
    `${config.api_base_url}/admin/auths/login`,
    params
  );
};

const adminInfo = () => {
  return RequestManager.v1.withAuthorize.get(
      `${config.api_base_url}/admin/admins/mine`
  );
};

const exported = {
  login,
  adminInfo
};

export default exported;
