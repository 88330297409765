import { RequestManager } from "utils";
import config from "configs";

const getPredictHistories = (params: any) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/predicts`,
    params
  );
};
const exported = {
  getPredictHistories,
};

export default exported;
