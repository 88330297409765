import { Typography } from "antd";
import React from "react";

const { Title } = Typography;

type PropsType = {
  title: string;
  extra?: React.ReactNode;
};
export default function PageHeader(props: PropsType) {
  return (
    <div className="page-header">
      <div className="page-header-heading">
        <div>
          <Title level={2}>{props.title}</Title>
        </div>
        <div>
          {props.extra &&
            //@ts-ignore
            props.extra.map((item: any) => item)}
        </div>
      </div>
    </div>
  );
}
