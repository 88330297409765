import { Table, Tag, Tooltip, Typography } from "antd";
import moment from "moment";
import React, {useMemo} from "react";
import { useAppSelector } from "stores";
import { Helpers } from "utils";
import configs from "../../../configs";
import {useLocation} from "react-router-dom";
import queryString from "query-string";

const { Text } = Typography;

type Props = {
  filter: any;
  onChangePage: (page: number) => void;
};

export default function List(props: Props) {
  const { predictHistories } = useAppSelector((state) => state.predictHistory);
  const location = useLocation();
  const query: any = useMemo(() => {
    const data: any = queryString.parse(location.search);
    return {
      transactionType: data.transactionType ? data.transactionType : "ALL",
    };
  }, [location.search]);

  const getReceive = (record: UserPredictHistoryType) => {
    switch (record.status) {
      case "WIN":
        return `+${Helpers.checkInt(record.predictAmount + record.amountWin)}`;
      case "LOST":
        return `-${Helpers.checkInt(record.predictAmount)}`;
      case "NONE":
        return "0";
    }
  };

  const columns: any = [
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "time",
      render: (value: string) => (
          <span>
          {moment(value).format("DD/MM/YYYY")} {moment(value).format("HH:mm")}
        </span>
      ),
      align: "left",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (value: string) => (
        <Tooltip title={value}>{value}</Tooltip>
      ),
    },
    {
      title: "Round",
      dataIndex: "roundId",
      key: "roundId",
      render: (value: string) => (
          <span>
          #{value}
        </span>
      ),
      align: "center",
    },
    {
      title: "Timeframe",
      dataIndex: "timeframe",
      key: "timeframe",
      render: (value: string) => (
          <span>
          {value} min
        </span>
      ),
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (value: string) => (
        <Tag
          color={
            value === "WIN"
              ? "green"
              : value === "NONE"
              ? "cyan"
              : undefined
          }
        >
          {value === "LOST" ? "LOSE" : value}
        </Tag>
      ),
    },
    {
      title: "Predict Amount",
      dataIndex: "predictAmount",
      key: "predictAmount",
      render: (value: number, record: UserPredictHistoryType) => (
        <div style={{ textAlign: "center" }}>
          {Helpers.checkInt(value)}
        </div>
      ),
      align: "center",
    },
    {
      title: "Receive",
      dataIndex: "amountWin",
      key: "amountWin",
      render: (value: string, record: UserPredictHistoryType) => (
        <Text
          strong
          type={
            record.status === "WIN"
              ? "success"
              : value === "LOSE"
              ? "danger"
              : undefined
          }
        >
          {getReceive(record)}
        </Text>
      ),
      align: "center",
    },
    {
      title: "Token",
      dataIndex: "coinType",
      key: "coinType",
      render: (value: string) => (
        <span>
          {value}
        </span>
      ),
      align: "center",
    },
  ];

  return (
    <div className="list-container">
      <Table
        dataSource={predictHistories.datas.map((item) => ({
          key: item._id,
          ...item,
        }))}
        columns={columns}
        pagination={{
          defaultPageSize: props.filter ? props.filter.pageSize : 15,
          defaultCurrent: props.filter ? props.filter.page : 1,
          current: props.filter ? props.filter.page : 1,
          showSizeChanger: false,
          total: predictHistories.meta.total,
          showTotal: (total: number) => `Total: ${total}`,
          onChange: (page) => props.onChangePage(page),
        }}
      />
    </div>
  );
}
