import React, {useEffect, useState} from "react";
import { Column } from "@ant-design/plots";
import { useAppSelector } from "stores";
import { Typography } from "antd";

const { Title } = Typography;

export default function DailyVolumeChart() {
  const { dailyVolume } = useAppSelector((state) => state.general);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (dailyVolume) {
      let arr = [] as any;
      dailyVolume.forEach((item) => {
        if (item.totalAmount) {
          const stakeItem = {
            name: "Total USD Volume",
            time: item._id,
            value: item.totalAmount,
          };
          arr.push(stakeItem);
        }
        if (item.totalTokenAmount) {
          const unstakeItem = {
            name: "Total IQT Volume",
            time: item._id,
            value: item.totalTokenAmount,
          };
          arr.push(unstakeItem);
        }
      });
      setData(arr);
    }
  }, [dailyVolume]);

  const config = {
    data: data,
    isGroup: true,
    xField: "time",
    yField: "value",
    seriesField: "name",
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  };

  return (
    <div className="form-item-container">
      <div style={{ marginBottom: 30 }}>
        <Title level={5}>Prediction</Title>
      </div>
      {
        //@ts-ignore
        <Column {...config} />
      }
    </div>
  );
}
