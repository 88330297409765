import { RequestManager } from "utils";
import config from "configs";

const listRound = () => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/rounds`
  );
};

const getHistories = (params: any) => {
  return RequestManager.v1.withAuthorize.get(
      `${config.api_base_url}/admin/rounds/histories`,
      params
  );
};

const setResultPredict = (params: any) => {
  const { id, ...otherParams } = params;
  return RequestManager.v1.withAuthorize.post(
    `${config.api_base_url}/admin/rounds/${id}`,
    otherParams
  );
};

const cancelControlPredict = (id: string) => {
  return RequestManager.v1.withAuthorize.delete(
    `${config.api_base_url}/admin/rounds/${id}/cancel-control`
  );
};

const exported = {
  listRound,
  setResultPredict,
  cancelControlPredict,
  getHistories
};

export default exported;
