import { Table, Typography } from "antd";
import React from "react";
import { useAppSelector } from "stores";
import { Helpers } from "utils";
import moment from "moment";

const { Text } = Typography;

type Props = {
  onChangePage: (page: number) => void;
  filter: any;
};

export default function List(props: Props) {
  const datas = useAppSelector((state) => state.user).farmingCommissionHistory.datas;
  const meta = useAppSelector((state) => state.user).farmingCommissionHistory.meta;

  const getType = (value: string) => {
    switch (value) {
      case "INTEREST_BONUS":
        return "Interest Bonus";
      case "COMMISSION_BONUS":
        return "Commission Bonus";
      case "SALE_LEVEL_BONUS":
        return "Sale Level Bonus";
      default:
        return "";
    }
  };

  const columns: any = [
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (value: Date) => moment(value).format("YYYY-MM-DD hh:mm:ss"),
    },
    {
      title: "Farming Position ID",
      dataIndex: "farmingPosition",
      key: "farmingPosition",
      align: "center",
      render: (farmingPosition: any) => "#"+farmingPosition.shortId,
    },
    {
      title: "Friend's User Name",
      dataIndex: "user",
      key: "user",
      align: "center",
      render: (user: CommissisonHistoryUserType) => user.shortId,
    },
    {
      title: "Referral Bonus Earned(USD)",
      dataIndex: "commissionAmount",
      key: "commissionAmount",
      align: "center",
    },
    {
      title: "Type",
      dataIndex: "commissionType",
      key: "commissionType",
      align: "center",
      render: (value: string) => getType(value),
    },
  ];

  return (
    <div className="list-container">
      <Table
        columns={columns}
        dataSource={datas.map((item) => ({ key: item.id, ...item }))}
        pagination={{
          defaultPageSize: props.filter ? props.filter.pageSize : 5,
          defaultCurrent: props.filter ? props.filter.page : 1,
          current: props.filter ? props.filter.page : 1,
          showSizeChanger: false,
          total: meta?.total,
          onChange: (page) => props.onChangePage(page),
          showTotal: (total: number) => `Total: ${total}`,
        }}
      />
    </div>
  );
}
