import { Button, Card, Form, Input, InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import { iqtSettingUpdate } from "stores/SettingSlice";

export default function IQTPriceSetting() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { iqt } = useAppSelector((state) => state.setting);

  useEffect(() => {
    if (iqt) {
      form.setFieldsValue({
        iqt: iqt,
      });
    }
  }, [iqt]);

  const onFinish = (values: any) => {
    const params = {
      value: values.iqt,
    };
    dispatch(iqtSettingUpdate(params));
  };

  return (
    <Card title="IQT Price" bordered={false}>
      <Form form={form} name="basic" onFinish={onFinish} layout="vertical">
        <Form.Item
          label="Token price"
          name="iqt"
          rules={[{ required: true, message: "Please input your value!" }]}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
