import React, { useEffect } from "react";
import Header from "./components/Header";
import { Col, Row } from "antd";
import PredictionSetting from "./components/PredictionSetting";
import WithdrawSetting from "./components/WithdrawSetting";
import { useAppDispatch, useAppSelector } from "stores";
import {eiqtSetting, iqtSetting, setting} from "stores/SettingSlice";
import VolumeBotSetting from "./components/VolumeBotSetting";
import IQTPriceSetting from "./components/IQTPriceSetting";
import EIQTPriceSetting from "./components/EIQTPriceSetting";
import StakingLevelSetting from "./components/StakingLevelSetting";
import SwapSetting from "./components/SwapSetting";
import WithdrawLimitSetting from "./components/WithdrawLimitSetting";
import TokenVolumeBotSetting from "./components/TokenVolumeBotSetting";
import TokenPredictionSetting from "./components/TokenPredictionSetting";
import FarmingSetting from "./components/FarmingSetting";
import EIQTPredictionSetting from "./components/EIQTPredictionSetting";
import EIQTVolumeBotSetting from "./components/EIQTVolumeBotSetting";

export default function SettingPage() {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state) => state.profile);
  const { isRefresh } = useAppSelector((state) => state.setting);

  useEffect(() => {
    if (token) {
      dispatch(setting());
      dispatch(iqtSetting());
      dispatch(eiqtSetting());
    }
  }, [token]);

  useEffect(() => {
    if (isRefresh) {
      dispatch(setting());
    }
  }, [isRefresh]);
  return (
    <div>
      <Header />
      <Row style={{ margin: 0 }} gutter={[16, 16]} className="p-12">
        <Col span={8}>
          <PredictionSetting />
        </Col>
        <Col span={8}>
          <TokenPredictionSetting />
        </Col>
        <Col span={8}>
          <EIQTPredictionSetting />
        </Col>
        <Col span={8}>
          <VolumeBotSetting />
        </Col>
        <Col span={8}>
          <TokenVolumeBotSetting />
        </Col>
        <Col span={8}>
          <EIQTVolumeBotSetting />
        </Col>
        <Col span={12}>
          <WithdrawSetting />
        </Col>
        <Col span={12}>
          <WithdrawLimitSetting />
        </Col>
        <Col span={12}>
          <SwapSetting />
        </Col>
        <Col span={12}>
          <FarmingSetting />
        </Col>
        <Col span={16}>
          <StakingLevelSetting />
        </Col>
        <Col span={8}>
          <Row style={{ margin: 0 }} gutter={[16, 16]} className="pb-15">
            <Col span={24}>
              <IQTPriceSetting />
            </Col>
            <Col span={24}>
              <EIQTPriceSetting />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
