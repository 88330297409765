import { Col, Row } from "antd";
import React from "react";
import InfoUser from "./InfoUser";
import ListOptions from "./ListOptions";
import BalanceView from "./BalanceView";
import PredictionInfo from "./PredictionInfo";
import StakingInfo from "./StakingInfo";
import TokenPredictionInfo from "./TokenPredictionInfo";
import PredictionCommission from "./PredictionCommission";
import FarmingInfo from "./FarmingInfo";

export default function Content() {
  return (
    <Row gutter={[16, 16]} className="detail-user p-15">
      <Col span={24}>
        <InfoUser />
      </Col>
      <Col span={24}>
        <BalanceView />
      </Col>
      <Col span={24}>
        <PredictionCommission />
      </Col>
      <Col span={12}>
        <PredictionInfo />
      </Col>
      <Col span={12}>
        <TokenPredictionInfo />
      </Col>
      <Col span={24}>
        <StakingInfo />
      </Col>
        <Col span={24}>
            <FarmingInfo />
        </Col>
      <Col span={24}>
        <ListOptions />
      </Col>
    </Row>
  );
}
