import React, { useEffect, useMemo, useState } from "react";
import List from "./List";
import Search from "./Search";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import Helpers from "utils/Helpers";
import { getUsers } from "stores/UserSlice";
import { useAppDispatch, useAppSelector } from "stores";
export default function Content() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [filter, setFilter] = useState(null);
  const { token } = useAppSelector((state) => state.profile);

  const query: any = useMemo(() => {
    const data: any = queryString.parse(location.search);
    return {
      page: data.page ? parseInt(data.page as string) : 1,
      pageSize: data.pageSize ? parseInt(data.pageSize as string) : 15,
      s: data.s ? data.s : null,
      isActive: data.isActive ? data.isActive : null,
      enableTwoFactor: data.enableTwoFactor ? data.enableTwoFactor : null,
      sponsor: data.sponsor ? data.sponsor : null
    };
  }, [location.search]);

  function onGetListUser() {
    if (filter) {
      dispatch(getUsers(filter));
    }
  }

  useEffect(() => {
    if (token) {
      onGetListUser();
    }
  }, [filter, token]);

  useEffect(() => {
    if (JSON.stringify(filter) !== JSON.stringify(Helpers.clean(query))) {
      setFilter({
        ...Helpers.clean(query),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, filter]);

  function onChangePage(page: number) {
    navigate({
      pathname: "/users",
      search: queryString.stringify({ ...query, page }),
    });
  }

  function onSearch(params: any) {
    const filterValue = { ...query, ...params };
    if (params.s === undefined) {
      delete filterValue.s;
    }
    if (params.isActive === null) {
      delete filterValue.isActive;
    }
    if (params.enableTwoFactor === null) {
      delete filterValue.enableTwoFactor;
    }
    if (params.sponsor === null) {
      delete filterValue.sponsor;
    }
    navigate({
      search: queryString.stringify({ ...filterValue }),
    });
  }
  return (
    <div className="content-container">
      <Search filter={filter} onSearch={onSearch} />
      <List filter={filter} onChangePage={onChangePage} />
    </div>
  );
}
