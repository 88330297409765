interface EnvInterface {
    greeting: string | undefined;
    api_base_url: string | undefined;
    bscExplorer: string;
    tronExplorer: string;
}
  
const env: EnvInterface = {
    greeting: process.env.REACT_APP_GREETING,
    api_base_url: process.env.REACT_APP_API_BASE_URL,
    bscExplorer: process.env.REACT_APP_BSC_EXPLORER as string,
    tronExplorer: process.env.REACT_APP_TRON_EXPLORER as string
};
  
  export default env;
  