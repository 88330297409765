import { Button, Popconfirm, Space, Typography } from "antd";
import { useAppDispatch } from "stores";
import PredictionSlice, { cancelControlPredict } from "stores/PredictionSlice";
const { Text } = Typography;

type Props = {
  round: RoundPredictType;
};

export default function HeaderRound(props: Props) {
  const { round } = props;
  const dispatch = useAppDispatch();

  const handleOnClickSetResult = () => {
    dispatch(
      PredictionSlice.actions.setChooseResult({
        isOpen: true,
        round,
      })
    );
  };
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Text strong>{round.status}</Text>
      <Space>
        <Text strong>#{round.shortId}</Text>
        <Button onClick={handleOnClickSetResult} size="small" type="primary">
          Set Result
        </Button>
        {round.adminControl ? (
          <Popconfirm
            title="Cancel control"
            description="Are you sure to cancel control this game?"
            onConfirm={() => dispatch(cancelControlPredict(round.id))}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small" type="primary" danger>
              Cancel Result
            </Button>
          </Popconfirm>
        ) : null}
      </Space>
    </div>
  );
}
