import {
  HomeOutlined,
  UserOutlined,
  TransactionOutlined,
  CreditCardOutlined,
  PlayCircleOutlined,
  SettingOutlined,
  ScheduleOutlined,
  HistoryOutlined,
  GiftOutlined,
} from "@ant-design/icons";
import React from "react";

const menusFull = [
  {
    key: "/general",
    icon: React.createElement(HomeOutlined),
    label: `General`,
  },
  {
    key: "/users",
    icon: React.createElement(UserOutlined),
    label: `Users`,
  },
  {
    key: "/transaction-histories",
    icon: React.createElement(TransactionOutlined),
    label: `Transaction Histories`,
  },
  {
    key: "/withdraw-requests",
    icon: React.createElement(CreditCardOutlined),
    label: `Withdraw Requests`,
  },
  {
    key: "/predict-histories",
    icon: React.createElement(TransactionOutlined),
    label: `Predict Histories`,
  },
  {
    key: "/promotions",
    icon: React.createElement(GiftOutlined),
    label: `Promotions`,
  },
  {
    key: "/settings",
    icon: React.createElement(SettingOutlined),
    label: `Setting`,
  },
  {
    key: "/predictions",
    icon: React.createElement(PlayCircleOutlined),
    label: `Prediction Configs`,
  },
  {
    key: "/prediction-histories",
    icon: React.createElement(HistoryOutlined),
    label: `Config Histories`,
  },
];

const menusAdmin = [
  {
    key: "/general",
    icon: React.createElement(HomeOutlined),
    label: `General`,
  },
  {
    key: "/users",
    icon: React.createElement(UserOutlined),
    label: `Users`,
  },
  {
    key: "/transaction-histories",
    icon: React.createElement(TransactionOutlined),
    label: `Transaction Histories`,
  },
  {
    key: "/withdraw-requests",
    icon: React.createElement(CreditCardOutlined),
    label: `Withdraw Requests`,
  },
  {
    key: "/predict-histories",
    icon: React.createElement(TransactionOutlined),
    label: `Predict Histories`,
  },
  {
    key: "/promotions",
    icon: React.createElement(GiftOutlined),
    label: `Promotions`,
  },
  {
    key: "/settings",
    icon: React.createElement(SettingOutlined),
    label: `Setting`,
  },
];

export { menusAdmin, menusFull };
