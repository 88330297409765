import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  current,
} from "@reduxjs/toolkit";
import PredictionApi from "apis/PredictionApi";
import CommonSlice from "./CommonSlice";
import _ from "lodash";
import { defaultMeta } from "../utils/Mocks/Common";

const initialState = {
  rounds: [] as RoundPredictType[],
  isRefresh: false as boolean,
  lastPrice: 0 as number,
  isLiveProcessing: false as boolean,
  chooseResult: {
    isOpen: false as boolean,
    round: null as RoundPredictType | null,
  },
  histories: {
    datas: [] as AdminControlType[],
    meta: defaultMeta as MetaType,
  },
};

export const listRound = createAsyncThunk(
  "prediction/listRound",
  async (_, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await PredictionApi.listRound();
      const { data } = response;
      return data.data;
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const getHistories = createAsyncThunk(
  "prediction/getHistories",
  async (params, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await PredictionApi.getHistories(params);
      const { data } = response;

      const meta = {
        page: data.data.page || 0,
        pageSize: data.data.pageSize || 0,
        total: data.data.total || 0,
      };
      return {
        meta: meta,
        datas: data.data.datas || [],
      };
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const setResultPredict = createAsyncThunk(
  "prediction/setResultPredict",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      await PredictionApi.setResultPredict(params);
      thunkApi.dispatch(
        CommonSlice.actions.showNotice({
          type: "success",
          message: "Success!",
          description: "Set result game success!!!",
        })
      );
      return "success";
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const cancelControlPredict = createAsyncThunk(
  "prediction/cancelControlPredict",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      await PredictionApi.cancelControlPredict(params);
      thunkApi.dispatch(
        CommonSlice.actions.showNotice({
          type: "success",
          message: "Success!",
          description: "Cancel control result game success!!!",
        })
      );
      return "success";
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

const PredictionSlice = createSlice({
  name: "prediction",
  initialState,
  reducers: {
    setLastPrice: (state, action: PayloadAction<number>) => {
      state.lastPrice = action.payload;
    },
    liveProcessing: (state) => {
      state.isLiveProcessing = true;
    },
    liveProcessCompleted: (state, action: PayloadAction<RoundPredictType>) => {
      state.isLiveProcessing = false;
      const currentRounds = current(state.rounds);
      const oldRound: RoundPredictType[] = currentRounds.filter(
        (item: RoundPredictType) => item.id !== action.payload.id
      );
      let newRounds = [];
      newRounds = [...oldRound, action.payload].filter(
        (round: RoundPredictType) =>
          round.status === "LIVE" || round.status === "NEXT"
      );
      state.rounds = _.sortBy(newRounds, "shortId") as RoundPredictType[];
    },
    addNewRound: (state, action: PayloadAction<RoundPredictType>) => {
      const currentRounds = current(state.rounds);
      action.payload.userPredicts = [];
      const newRounds = [...currentRounds, action.payload].filter(
        (round: RoundPredictType) =>
          round.status === "LIVE" || round.status === "NEXT"
      );
      state.rounds = _.sortBy(newRounds, "shortId") as RoundPredictType[];
    },
    updateRound: (state, action: PayloadAction<RoundPredictType>) => {
      const rounds = current(state.rounds);
      const oldRound: RoundPredictType[] = rounds.filter(
        (item: RoundPredictType) => item.id !== action.payload.id
      );
      const updatedRound: RoundPredictType | undefined = rounds.find(
        (item: RoundPredictType) => item.id === action.payload.id
      );
      let newRounds = [];
      if (updatedRound) {
        newRounds = [
          ...oldRound,
          {
            ...action.payload,
            userPredicts: updatedRound.userPredicts,
          },
        ];
      } else {
        newRounds = [...oldRound, action.payload];
      }
      state.rounds = _.sortBy(
        newRounds.filter(
          (round: RoundPredictType) =>
            round.status === "LIVE" || round.status === "NEXT"
        ),
        "shortId"
      ) as RoundPredictType[];
    },
    setChooseResult: (
      state,
      action: PayloadAction<{ isOpen: boolean; round: RoundPredictType | null }>
    ) => {
      state.chooseResult.isOpen = action.payload.isOpen;
      state.chooseResult.round = action.payload.round;
    },
    newUserPredict: (state, action: PayloadAction<UserPredictType>) => {
      const rounds = current(state.rounds);
      const oldRound: RoundPredictType[] = rounds.filter(
        (item: RoundPredictType) => item.id !== action.payload.round
      );
      const updatedRound: RoundPredictType | undefined = rounds.find(
        (item: RoundPredictType) => item.id === action.payload.round
      );
      let newRounds: any[] = [];
      if (updatedRound) {
        const exists = updatedRound.userPredicts.find(
          (item) => item.id === action.payload.id
        );
        if (exists) {
          newRounds = [
            ...oldRound,
            {
              ...updatedRound,
              userPredicts: [...updatedRound.userPredicts],
            },
          ];
        } else {
          newRounds = [
            ...oldRound,
            {
              ...updatedRound,
              userPredicts: [action.payload, ...updatedRound.userPredicts],
            },
          ];
        }
      }
      state.rounds = _.sortBy(newRounds, "shortId") as RoundPredictType[];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listRound.fulfilled, (state, action) => {
      state.isRefresh = false;
      if (action.payload) {
        state.rounds = action.payload;
      }
    });
    builder.addCase(getHistories.fulfilled, (state, action) => {
      state.isRefresh = false;
      if (action.payload) {
        state.histories.meta = action.payload.meta;
        state.histories.datas = action.payload.datas;
      }
    });
    builder.addCase(setResultPredict.fulfilled, (state, action) => {
      if (action.payload) {
        state.chooseResult.isOpen = false;
        state.chooseResult.round = null;
        state.isRefresh = true;
      }
    });
    builder.addCase(cancelControlPredict.fulfilled, (state, action) => {
      if (action.payload) {
        state.chooseResult.isOpen = false;
        state.chooseResult.round = null;
        state.isRefresh = true;
      }
    });
  },
});

export default PredictionSlice;
