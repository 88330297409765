import { Col, Row } from "antd";
import moment from "moment";
import queryString from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helpers } from "utils";
import Filter from "./components/Filter";
import GeneralView from "./components/GeneralView";
import Header from "./components/Header";
import {
  getStaticGeneral,
  getDailyVolume,
  getDailyActiveUser,
  getStaticStakeGeneral,
  dailyStaking, getStaticFarmingGeneral,
} from "stores/GeneralSlice";
import { useAppDispatch } from "stores";
import DailyVolumeChart from "./components/DailyVolumeChart";
import DailyActiveUserChart from "./components/DailyActiveUserChart";
import StakeGeneralView from "./components/StakeGeneralView";
import BalanceView from "./components/BalanceView";
import StakingChart from "./components/StakingChart";
import FarmingGeneralView from "./components/FarmingGeneralView";
export default function General() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [filter, setFilter] = useState(null);

  const query: any = useMemo(() => {
    const data: any = queryString.parse(location.search);
    return {
      from: data.from
        ? data.from
        : moment().subtract(30, "d").format("YYYY-MM-DD"),
      to: data.to ? data.to : moment().format("YYYY-MM-DD"),
    };
  }, [location.search]);

  useEffect(() => {
    if (JSON.stringify(filter) !== JSON.stringify(Helpers.clean(query))) {
      setFilter({
        ...Helpers.clean(query),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (filter) {
      dispatch(getStaticGeneral(filter));
      dispatch(getStaticStakeGeneral(filter));
      dispatch(getStaticFarmingGeneral(filter));
      dispatch(getDailyVolume(filter));
      dispatch(getDailyActiveUser(filter));
      dispatch(dailyStaking(filter));
    }
  }, [filter]);

  const onSearch = (params: any) => {
    const filterValue = { ...query, ...params };
    navigate({
      search: queryString.stringify({ ...filterValue }),
    });
  };

  return (
    <div>
      <Header />
      <Row style={{ margin: 0 }} gutter={[16, 16]} className="p-15">
        <Col span={24}>
          <Filter filter={filter} onSearch={onSearch} />
        </Col>
        <Col span={24}>
          <GeneralView />
        </Col>
        <Col span={24}>
          <StakeGeneralView />
        </Col>
        <Col span={24}>
          <FarmingGeneralView />
        </Col>
        <Col span={24}>
          <BalanceView />
        </Col>
        <Col span={24}>
          <DailyVolumeChart />
        </Col>
        <Col span={24}>
          <StakingChart />
        </Col>
        <Col span={24}>
          <DailyActiveUserChart />
        </Col>
      </Row>
    </div>
  );
}
