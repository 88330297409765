import { Modal, Radio, Typography } from "antd";
import { round } from "lodash";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import PredictionSlice, { setResultPredict } from "stores/PredictionSlice";

const { Text } = Typography;

export default function PopupChooseResult() {
  const dispatch = useAppDispatch();
  const { chooseResult } = useAppSelector((state) => state.prediction);
  const [position, setPosition] = useState("");

  const handleCancel = () => {
    dispatch(
      PredictionSlice.actions.setChooseResult({
        isOpen: false,
        round: null,
      })
    );
  };

  const handleOk = () => {
    if (position) {
      const params = {
        id: chooseResult.round?.id,
        position,
      };
      dispatch(setResultPredict(params));
    }
  };

  useEffect(() => {
    if (chooseResult.round) {
      if (chooseResult.round.adminControl) {
        setPosition(chooseResult.round.adminControl.positionWin);
      }
    } else {
      setPosition("");
    }
  }, [chooseResult.round]);

  return (
    <Modal
      title="Choose Result"
      open={chooseResult.isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <div style={{ textAlign: "center" }}>
        <Radio.Group
          onChange={(e: any) => setPosition(e.target.value)}
          value={position}
        >
          <Radio value={"UP"}>
            <Text strong type="success">
              UP
            </Text>
          </Radio>
          <Radio value={"DOWN"}>
            <Text strong type="danger">
              DOWN
            </Text>
          </Radio>
          <Radio value={"DRAW"}>
            <Text strong type="danger">
              DRAW
            </Text>
          </Radio>
        </Radio.Group>
      </div>
    </Modal>
  );
}
