import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SettingApi from "apis/SettingApi";
import CommonSlice from "./CommonSlice";

const initialState = {
  setting: [] as SettingType[],
  iqt: 0 as number,
  eiqt: 0 as number,
  isRefresh: false as boolean,
};

export const setting = createAsyncThunk(
  "setting/setting",
  async (_, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await SettingApi.setting();
      const { data } = response;
      if (data) {
        return data.data as SettingType[];
      }
      return [];
    } catch (error) {
      return [];
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const iqtSetting = createAsyncThunk(
  "setting/iqtSetting",
  async (_, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await SettingApi.iqtSetting();
      const { data } = response;
      if (data) {
        return data.data.value as number;
      }
      return 0;
    } catch (error) {
      return 0;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const eiqtSetting = createAsyncThunk(
    "setting/eiqtSetting",
    async (_, thunkApi: any) => {
        thunkApi.dispatch(CommonSlice.actions.incrementLoading());
        try {
            const response = await SettingApi.eiqtSetting();
            const { data } = response;
            if (data) {
                return data.data.value as number;
            }
            return 0;
        } catch (error) {
            return 0;
        } finally {
            thunkApi.dispatch(CommonSlice.actions.decrementLoading());
        }
    }
);

export const update = createAsyncThunk(
  "setting/update",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      await SettingApi.updateSetting(params);
      return "success";
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const iqtSettingUpdate = createAsyncThunk(
  "setting/iqtSettingUpdate",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await SettingApi.iqtSettingUpdate(params);
      const { data } = response;
      if (data) {
        thunkApi.dispatch(
          CommonSlice.actions.showNotice({
            type: "success",
            message: "Success!",
            description: "Update successful",
          })
        );
        return data.data.value as number;
      }
      return 0;
    } catch (error) {
      thunkApi.dispatch(
        CommonSlice.actions.showNotice({
          type: "error",
          message: "Error!",
          description: "Update failed",
        })
      );
      return 0;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const eiqtSettingUpdate = createAsyncThunk(
    "setting/eiqtSettingUpdate",
    async (params: any, thunkApi: any) => {
        thunkApi.dispatch(CommonSlice.actions.incrementLoading());
        try {
            const response = await SettingApi.eiqtSettingUpdate(params);
            const { data } = response;
            if (data) {
                thunkApi.dispatch(
                    CommonSlice.actions.showNotice({
                        type: "success",
                        message: "Success!",
                        description: "Update successful",
                    })
                );
                return data.data.value as number;
            }
            return 0;
        } catch (error) {
            thunkApi.dispatch(
                CommonSlice.actions.showNotice({
                    type: "error",
                    message: "Error!",
                    description: "Update failed",
                })
            );
            return 0;
        } finally {
            thunkApi.dispatch(CommonSlice.actions.decrementLoading());
        }
    }
);

const SettingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setIsRefresh: (state, action: PayloadAction<boolean>) => {
      state.isRefresh = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setting.fulfilled, (state, action) => {
      state.isRefresh = false;
      if (action.payload) {
        state.setting = action.payload;
      }
    });
    builder.addCase(iqtSetting.fulfilled, (state, action) => {
      if (action.payload) {
        state.iqt = action.payload;
      }
    });
    builder.addCase(iqtSettingUpdate.fulfilled, (state, action) => {
      if (action.payload) {
        state.iqt = action.payload;
      }
    });
      builder.addCase(eiqtSetting.fulfilled, (state, action) => {
          if (action.payload) {
              state.eiqt = action.payload;
          }
      });
      builder.addCase(eiqtSettingUpdate.fulfilled, (state, action) => {
          if (action.payload) {
              state.eiqt = action.payload;
          }
      });
  },
});

export default SettingSlice;
