import queryString from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "stores";
import TransactionHistorySlice, { coinTransaction } from "stores/TransactionHistorySlice";
import { Helpers } from "utils";
import Header from "./components/Header";
import List from "./components/List";

export default function SettingPage() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [filter, setFilter] = useState(null);
  const { token } = useAppSelector((state) => state.profile);
  const { isRefresh } = useAppSelector((state) => state.transaction);
  const query: any = useMemo(() => {
    const data: any = queryString.parse(location.search);
    return {
      page: data.page ? parseInt(data.page as string) : 1,
      pageSize: data.pageSize ? parseInt(data.pageSize as string) : 15,
      transactionType: "WITHDRAW",
      status: "WAIT_ADMIN_CONFIRM",
    };
  }, [location.search]);

  useEffect(() => {
    if (JSON.stringify(filter) !== JSON.stringify(Helpers.clean(query))) {
      setFilter({
        ...Helpers.clean(query),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (filter && token) {
      dispatch(coinTransaction(filter));
    }
  }, [filter, token]);

  useEffect(() => {
    if (isRefresh && token) {
      dispatch(coinTransaction(filter));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh, token]);

  useEffect(() => {
    dispatch(TransactionHistorySlice.actions.clearTransactionData())
  }, [])

  function onChangePage(page: number) {
    navigate({
      search: queryString.stringify({ ...query, page }),
    });
  }

  return (
    <div>
      <Header />
      <div className="content-container">
        <List filter={filter} onChangePage={onChangePage} />
      </div>
    </div>
  );
}
