import { RequestManager } from "utils";
import config from "configs";

const general = (params: any) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/statics/general`,
    params
  );
};

const stakeGeneral = (params: any) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/statics/stake-general`,
    params
  );
};

const farmingGeneral = (params: any) => {
  return RequestManager.v1.withAuthorize.get(
      `${config.api_base_url}/admin/statics/farming-general`,
      params
  );
};

const dailyVolume = (params: any) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/statics/daily-volume`,
    params
  );
};

const dailyActiveUser = (params: any) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/statics/daily-active-user`,
    params
  );
};

const dailyStaking = (params: any) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/statics/daily-stake-volume`,
    params
  );
};

const exported = {
  general,
  dailyVolume,
  dailyActiveUser,
  stakeGeneral,
  dailyStaking,
  farmingGeneral
};

export default exported;
